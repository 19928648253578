<template>
  <div :class="$options.name">
    <banded-section
      :title="sidebarTitle"
      :tooltip="sidebarDescription"
      :collapsable="false"
    >
      <form
        v-loading="isLoading"
        @submit.prevent="submit"
      >
        <div class="columns">
          <div class="column is-3">
            <subheading>{{ $t('pages.relations.create.heading_personal_information') }}</subheading>

            <p class="has-text-grey">
              {{ $t('pages.relations.create.description_personal_information') }}
            </p>
          </div>

          <div class="column is-9">
            <div class="columns">
              <div class="column">
                <form-field
                  :label="$t('general.name')"
                  :validator="$v.form.mapped_names"
                >
                  <span
                    v-for="(v, index) in $v.form.mapped_names.$each.$iter"
                    :key="index"
                  >
                    <input
                      v-model.trim="v.name.$model"
                      :placeholder="$t('pages.relations.create.name_placeholder')"
                      class="input"
                      :class="{ 'is-danger': v.name.$error }"
                      name="names[]"
                      :data-testid="`relationNames-${index}`"
                      type="text"
                    >

                    <div v-if="v.name.$error" class="help is-danger">
                      Name is required.
                    </div>
                  </span>
                </form-field>
              </div>

              <div class="column">
                <form-field
                  :label="$t('forms.country')"
                  :validator="$v.form.location_country"
                >
                  <country-picker
                    v-model="form.location_country"
                    :placeholder="$t('forms.country_select')"
                    class="relationCountry"
                    data-testid="relationCountries"
                    default-first-option
                  />
                </form-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <form-field
                  :label="$t('pages.relations.create.photo_label')"
                  :validator="$v.form.picture_url"
                >
                  <input
                    v-model="form.picture_url"
                    :placeholder="$t('pages.relations.create.photo_placeholder')"
                    data-testid="relationPictureUrl"
                    class="input"
                    name="photo"
                    type="text"
                  >
                </form-field>
              </div>

              <div class="column">
                <form-field
                  :label="$t('pages.relations.create.contactinfo_link_label')"
                  :validator="$v.form.mapped_urls_website"
                >
                  <span
                    v-for="(v, index) in $v.form.mapped_urls_website.$each.$iter"
                    :key="index"
                  >
                    <input
                      v-model="v.url.$model"
                      :data-testid="`relationUrls-Website-${index}`"
                      class="input"
                      :class="[{ 'is-danger': v.url.$error }]"
                      name="urls[website][]"
                      type="text"
                    >
                  </span>
                </form-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <form-field
                  :label="$t('forms.email_no_dash')"
                  :validator="$v.form.mapped_emails"
                >
                  <span
                    v-for="(v, index) in $v.form.mapped_emails.$each.$iter"
                    :key="index"
                  >
                    <input
                      v-model.trim="v.email.$model"
                      :placeholder="$t('pages.relations.create.contactinfo_email_placeholder')"
                      :data-testid="`relationEmails-${index}`"
                      class="input is-width-5/6"
                      :class="[{ 'is-danger': v.email.$error, 'm-b-s': index !== form.mapped_emails.length - 1 }]"
                      name="emails[]"
                      type="email"
                    >

                    <v-button
                      :disabled="form.mapped_emails.length <= 1"
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_emails.splice(index, 1)"
                    >
                      <span class="icon">
                        <v-icon type="trash" />
                      </span>
                    </v-button>

                    <v-button
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_emails.push({ email: '' })"
                    >
                      <span class="icon">
                        <v-icon type="plus" />
                      </span>
                    </v-button>
                  </span>
                </form-field>
              </div>

              <div class="column">
                <form-field
                  :label="$t('general.phone')"
                  :validator="$v.form.mapped_phones"
                >
                  <span
                    v-for="(v, index) in $v.form.mapped_phones.$each.$iter"
                    :key="index"
                  >
                    <input
                      v-model.trim="v.phone.$model"
                      :placeholder="$t('pages.relations.create.contactinfo_phone_placeholder')"
                      :data-testid="`relationPhones-${index}`"
                      class="input is-width-5/6"
                      :class="[{ 'is-danger': v.phone.$error, 'm-b-s': index !== form.mapped_phones.length - 1 }]"
                      name="phones[]"
                      type="phone"
                    >

                    <v-button
                      :disabled="form.mapped_phones.length <= 1"
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_phones.splice(index, 1)"
                    >
                      <span class="icon">
                        <v-icon type="trash" />
                      </span>
                    </v-button>

                    <v-button
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_phones.push({ phone: '' })"
                    >
                      <span class="icon">
                        <v-icon type="plus" />
                      </span>
                    </v-button>
                  </span>
                </form-field>
              </div>
            </div>
          </div>
        </div>

        <hr class="m-v-l">

        <div class="columns">
          <div class="column is-3">
            <subheading>{{ $t('pages.relations.create.heading_work_information') }}</subheading>

            <p class="has-text-grey">
              {{ $t('pages.relations.create.description_work_information') }}
            </p>
          </div>

          <div class="column is-9">
            <div class="columns">
              <div class="column">
                <form-field
                  :label="$t('pages.relations.create.brand_name_label')"
                  :validator="$v.form.employment_association"
                >
                  <input
                    v-model="form.employment_association"
                    :placeholder="$t('pages.relations.create.brand_name_placeholder')"
                    data-testid="relationEmploymentAssociation"
                    class="input"
                    name="employment_association"
                    type="text"
                  >
                </form-field>
              </div>

              <div class="column">
                <form-field
                  :label="$t('pages.relations.create.brand_position_label')"
                  :validator="$v.form.employment_role"
                >
                  <input
                    v-model="form.employment_role"
                    :placeholder="$t('pages.relations.create.brand_position_placeholder')"
                    data-testid="relationEmploymentRole"
                    class="input"
                    name="employment_role"
                    type="text"
                  >
                </form-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <form-field
                  :label="$t('pages.relations.create.categories_label')"
                  :validator="$v.form.categories"
                >
                  <el-select
                    v-model="form.categories"
                    default-first-option
                    multiple
                    class="relationCategories"
                    popper-class="relationCategoriesModal"
                    filterable
                    data-testid="relationCategories"
                  >
                    <el-option
                      v-for="category in categories"
                      :key="category.slug"
                      :label="category.name"
                      :value="category.slug"
                    />
                  </el-select>
                </form-field>
              </div>

              <div class="column">
                <form-field
                  :label="$t('pages.relations.create.topics_label')"
                  :validator="$v.form.tags"
                >
                  <el-select
                    v-model="form.tags"
                    :placeholder="$t('pages.relations.create.topics_placeholder')"
                    class="relationTopics Add-relation__topics--no-arrow"
                    popper-class="relationTopicsModal"
                    data-testid="relationTags"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                  />
                </form-field>
              </div>
            </div>
          </div>
        </div>

        <hr class="m-v-l">

        <div class="columns">
          <div class="column is-3">
            <subheading>{{ $t('pages.relations.create.heading_social_media_information') }}</subheading>

            <p class="has-text-grey">
              {{ $t('pages.relations.create.description_social_media_information') }}
            </p>
          </div>

          <div class="column is-9">
            <div class="columns">
              <div class="column">
                <form-field
                  :label="$t('pages.relations.create.contactinfo_youtube_label')"
                  :validator="$v.form.mapped_urls_youtube"
                >
                  <span
                    v-for="(v, index) in $v.form.mapped_urls_youtube.$each.$iter"
                    :key="index"
                  >
                    <input
                      v-model.trim="v.url.$model"
                      :data-testid="`relationUrls-YouTube-${index}`"
                      class="input is-width-5/6"
                      :class="[{ 'is-danger': v.url.$error, 'm-b-s': index !== form.mapped_urls_youtube.length - 1 }]"
                      name="urls_youtube[]"
                      type="text"
                    >

                    <v-button
                      :disabled="form.mapped_urls_youtube.length <= 1"
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_urls_youtube.splice(index, 1)"
                    >
                      <span class="icon">
                        <v-icon type="trash" />
                      </span>
                    </v-button>

                    <v-button
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_urls_youtube.push({ url: '' })"
                    >
                      <span class="icon">
                        <v-icon type="plus" />
                      </span>
                    </v-button>
                  </span>
                </form-field>
              </div>

              <div class="column">
                <form-field
                  :label="$t('pages.relations.create.contactinfo_twitter_label')"
                  :validator="$v.form.mapped_urls_twitter"
                >
                  <span
                    v-for="(v, index) in $v.form.mapped_urls_twitter.$each.$iter"
                    :key="index"
                  >
                    <input
                      v-model.trim="v.url.$model"
                      :data-testid="`relationUrls-Twitter-${index}`"
                      class="input is-width-5/6"
                      :class="[{ 'is-danger': v.url.$error, 'm-b-s': index !== form.mapped_urls_twitter.length - 1 }]"
                      name="urls_twitter[]"
                      type="text"
                    >

                    <v-button
                      :disabled="form.mapped_urls_twitter.length <= 1"
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_urls_twitter.splice(index, 1)"
                    >
                      <span class="icon">
                        <v-icon type="trash" />
                      </span>
                    </v-button>

                    <v-button
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_urls_twitter.push({ url: '' })"
                    >
                      <span class="icon">
                        <v-icon type="plus" />
                      </span>
                    </v-button>
                  </span>
                </form-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <form-field
                  :label="$t('pages.relations.create.contactinfo_facebook_label')"
                  :validator="$v.form.mapped_urls_facebook"
                >
                  <span
                    v-for="(v, index) in $v.form.mapped_urls_facebook.$each.$iter"
                    :key="index"
                  >
                    <input
                      v-model.trim="v.url.$model"
                      :data-testid="`relationUrls-Facebook-${index}`"
                      class="input is-width-5/6"
                      :class="[{ 'is-danger': v.url.$error, 'm-b-s': index !== form.mapped_urls_facebook.length - 1 }]"
                      name="urls_facebook[]"
                      type="text"
                    >

                    <v-button
                      :disabled="form.mapped_urls_facebook.length <= 1"
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_urls_facebook.splice(index, 1)"
                    >
                      <span class="icon">
                        <v-icon type="trash" />
                      </span>
                    </v-button>

                    <v-button
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_urls_facebook.push({ url: '' })"
                    >
                      <span class="icon">
                        <v-icon type="plus" />
                      </span>
                    </v-button>
                  </span>
                </form-field>
              </div>

              <div class="column">
                <form-field
                  :label="$t('pages.relations.create.contactinfo_instagram_label')"
                  :validator="$v.form.mapped_urls_instagram"
                >
                  <span
                    v-for="(v, index) in $v.form.mapped_urls_instagram.$each.$iter"
                    :key="index"
                  >
                    <input
                      v-model.trim="v.url.$model"
                      :data-testid="`relationUrls-Instagram-${index}`"
                      class="input is-width-5/6"
                      :class="[{ 'is-danger': v.url.$error, 'm-b-s': index !== form.mapped_urls_instagram.length - 1 }]"
                      name="urls_instagram[]"
                      type="text"
                    >

                    <v-button
                      :disabled="form.mapped_urls_instagram.length <= 1"
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_urls_instagram.splice(index, 1)"
                    >
                      <span class="icon">
                        <v-icon type="trash" />
                      </span>
                    </v-button>

                    <v-button
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_urls_instagram.push({ url: '' })"
                    >
                      <span class="icon">
                        <v-icon type="plus" />
                      </span>
                    </v-button>
                  </span>
                </form-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <form-field
                  :label="$t('pages.relations.create.contactinfo_linkedin_label')"
                  :validator="$v.form.mapped_urls_linkedin"
                >
                  <span
                    v-for="(v, index) in $v.form.mapped_urls_linkedin.$each.$iter"
                    :key="index"
                  >
                    <input
                      v-model.trim="v.url.$model"
                      :data-testid="`relationUrls-LinkedIn-${index}`"
                      class="input is-width-5/6"
                      :class="[{ 'is-danger': v.url.$error, 'm-b-s': index !== form.mapped_urls_linkedin.length - 1 }]"
                      name="urls_linkedin[]"
                      type="text"
                    >

                    <v-button
                      :disabled="form.mapped_urls_linkedin.length <= 1"
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_urls_linkedin.splice(index, 1)"
                    >
                      <span class="icon">
                        <v-icon type="trash" />
                      </span>
                    </v-button>

                    <v-button
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_urls_linkedin.push({ url: '' })"
                    >
                      <span class="icon">
                        <v-icon type="plus" />
                      </span>
                    </v-button>
                  </span>
                </form-field>
              </div>

              <div class="column">
                <form-field
                  :label="$t('pages.relations.create.contactinfo_pinterest_label')"
                  :validator="$v.form.mapped_urls_pinterest"
                >
                  <span
                    v-for="(v, index) in $v.form.mapped_urls_pinterest.$each.$iter"
                    :key="index"
                  >
                    <input
                      v-model.trim="v.url.$model"
                      :data-testid="`relationUrls-Pinterest-${index}`"
                      class="input is-width-5/6"
                      :class="[{ 'is-danger': v.url.$error, 'm-b-s': index !== form.mapped_urls_pinterest.length - 1 }]"
                      name="urls_pinterest[]"
                      type="text"
                    >

                    <v-button
                      :disabled="form.mapped_urls_pinterest.length <= 1"
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_urls_pinterest.splice(index, 1)"
                    >
                      <span class="icon">
                        <v-icon type="trash" />
                      </span>
                    </v-button>

                    <v-button
                      class="is-primary has-icon is-plain is-narrow"
                      type="button"
                      @click="form.mapped_urls_pinterest.push({ url: '' })"
                    >
                      <span class="icon">
                        <v-icon type="plus" />
                      </span>
                    </v-button>
                  </span>
                </form-field>
              </div>
            </div>
          </div>
        </div>

        <hr class="m-v-l">

        <div
          v-if="isNewModel"
          class="has-text-right m-b-m"
          data-testid="stayOnPageAfterSave"
        >
          {{ $t('pages.base_media_profile_manager.add_another') }}
          <el-switch v-model="stayOnPageAfterSave" />
        </div>

        <div class="buttons is-right">
          <v-button
            type="button"
            class="is-text"
            @click="cancelForm"
          >
            {{ $t('forms.cancel') }}
          </v-button>

          <v-button
            :loading="form.isLoading"
            data-testid="createRelation"
            class="is-primary is-wider"
            type="button"
            @click="submit"
          >
            {{ $t('forms.save') }}
          </v-button>
        </div>
      </form>
    </banded-section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'

import Form from '@/services/forms/Form'
import { isSimpleUrl } from '@hypefactors/shared/js/utils/validation'

function createFormInstance () {
  return new Form({
    picture_url: { value: null, rules: { simple_url: isSimpleUrl } },

    // names: { value: [], rules: { required, $each: { name: { required } } } },
    // emails: { value: [], rules: { required, $each: { email: { required, email } } } },
    // phones: { value: [], rules: { $each: { phone: { } } } },
    categories: { value: [], rules: { required } },
    tags: { value: [] },

    location_country: { value: null, rules: { required } },
    location_region: { value: null },

    employment_association: { value: '' },
    employment_role: { value: '' },
    employment_country: { value: '' },
    employment_website: { value: '' },

    mapped_names: { value: [], rules: { required, $each: { name: { required } } } },
    mapped_emails: { value: [], rules: { required, $each: { email: { required, email } } } },
    mapped_phones: { value: [], rules: { $each: { phone: { } } } },

    mapped_urls_website: { value: [], rules: { $each: { url: { simple_url: isSimpleUrl } } } },
    mapped_urls_facebook: { value: [], rules: { $each: { url: { simple_url: isSimpleUrl } } } },
    mapped_urls_instagram: { value: [], rules: { $each: { url: { simple_url: isSimpleUrl } } } },
    mapped_urls_linkedin: { value: [], rules: { $each: { url: { simple_url: isSimpleUrl } } } },
    mapped_urls_pinterest: { value: [], rules: { $each: { url: { simple_url: isSimpleUrl } } } },
    mapped_urls_twitter: { value: [], rules: { $each: { url: { simple_url: isSimpleUrl } } } },
    mapped_urls_youtube: { value: [], rules: { $each: { url: { simple_url: isSimpleUrl } } } }
  })
}

export default {
  name: 'BaseProfileManager',

  data () {
    return {
      isLoading: false,
      stayOnPageAfterSave: false,
      form: createFormInstance()
    }
  },

  computed: {
    ...mapGetters(['countries']),

    ...mapGetters('connect/categories', {
      'categories': 'allTranslated'
    }),

    isNewModel () {
      return true
    },

    sidebarTitle () {
      return ''
    },

    sidebarDescription () {
      return ''
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  beforeRouteLeave (to, from, next) {
    if (!this.form.hasChanges()) return next()

    return this.$confirm(this.$t('general.confirm_unsaved_changes'))
      .then(() => {
        next()
      })
      .catch(() => null)
  },

  mounted () {
    this.getCategories()
  },

  methods: {
    ...mapActions('connect/categories', {
      'fetchCategories': 'fetch'
    }),

    getCategories () {
      // TODO: Prevent the API call if we already have the categories
      return this.fetchCategories()
    },

    cleanNestedArray (array) {
      return array.filter(data => {
        return Object.values(data).filter(Boolean).length !== 0
      })
    },

    submit () {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        this.$notify.error('Please check the form for errors')

        return
      }

      this.form.setPayloadTransformer((data, changed) => {
        const transformedData = {
          picture_url: this.form.picture_url,
          names: this.cleanNestedArray(this.form.mapped_names).map((value) => value.name),
          emails: this.cleanNestedArray(this.form.mapped_emails).map((value) => value.email),
          phones: this.cleanNestedArray(this.form.mapped_phones).map((value) => value.phone),
          urls: {
            website: this.cleanNestedArray(this.form.mapped_urls_website).map((value) => value.url),
            facebook: this.cleanNestedArray(this.form.mapped_urls_facebook).map((value) => value.url),
            instagram: this.cleanNestedArray(this.form.mapped_urls_instagram).map((value) => value.url),
            linkedin: this.cleanNestedArray(this.form.mapped_urls_linkedin).map((value) => value.url),
            pinterest: this.cleanNestedArray(this.form.mapped_urls_pinterest).map((value) => value.url),
            twitter: this.cleanNestedArray(this.form.mapped_urls_twitter).map((value) => value.url),
            youtube: this.cleanNestedArray(this.form.mapped_urls_youtube).map((value) => value.url)
          },
          // associations: this.form.associations,
          // locations: this.form.locations,
          associations: this.cleanNestedArray([
            {
              association: this.form.employment_association,
              role: this.form.employment_role,
              country_code: this.form.employment_country,
              website: this.form.employment_website
            }
          ]),
          locations: this.cleanNestedArray([
            {
              country_code: this.form.location_country,
              region: this.form.location_region
            }
          ]),
          categories: this.form.categories,
          tags: this.form.tags
        }

        if (!this.isNewModel) {
          return transformedData
        }

        return {
          profile_data: transformedData
        }
      })

      const promise = this.submitHandler()

      promise
        .then(this.onSuccessHandler)
        .catch(this.onErrorHandler)
    },

    submitHandler () {
      if (this.isNewModel) {
        return this.form.post(`/connect/relations?brand=${this.activeBrandId}`)
      }

      return this.form.patch(`/connect/relations/${this.relationId}`)
    },

    cancelForm () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss">
  @import "~utils";

  .Add-relation__topics--no-arrow {
    .el-select__caret {
      display: none
    }
  }
</style>
